// Custom fonts and colors
$font-text: 'Barlow', sans-serif;
$font-heading: 'Pompiere', 'Klee One', cursive;
$color-accent: rgb(125, 101, 199);
$color-light: rgb(233, 230, 242);
$color-grey: 	rgb(122, 122, 122);
$color-dark: rgb(36, 36, 51);

// Import the Bulma framework
@use 'bulma/sass' with (
  // Custom fonts and colors
  $family-primary: $font-text,
  $family-secondary: $font-heading,
  $primary: $color-accent,
  $dark: $color-dark,
  $light: $color-light,
  $link: $color-grey,

  // Other variables
  $body-overflow-y: auto,
  $dimensions: 16 24 32 48 64 96 128 192 256,

  $section-padding: 1.5rem 1.5rem,
  $section-padding-desktop: 3rem 3rem,

  $footer-background-color: var(--bulma-light),
  $footer-padding: 1.5rem,
);

// Import the fonts
@import 'https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&family=Klee+One&family=Pompiere&display=swap';
